<template>
  <div class="notfound">
    <div class="container view col center middle">
      <h1 class="notfound__title"><span class="notfound__code">404 -</span> {{ $t('notfoundTitle') }}</h1>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.notfound {
  background-color: $background-grey;

  &__title {
    margin: 0;
  }

  &__code {
    opacity: 0.3;
    margin-right: 0.5rem;
    font-weight: 300;
  }
}
</style>
